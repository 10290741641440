import React from 'react'


import { graphql } from 'gatsby'
import { I18n } from 'react-i18next'
import { withI18next } from 'gatsby-plugin-i18next'
import { getContentTranslation } from '../helpers'


import GenericLayout from '../layouts/GenericLayout';
import PageSection from '../components/PageSection';

 const Galeria = () => {
    return <GenericLayout>
        <PageSection>
            <header className="major">
                <h1>Galéria</h1>
            </header>
        
        </PageSection>
    </GenericLayout>
}

export default withI18next()(Galeria)

export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`