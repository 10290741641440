import React from 'react'
import PropTypes from 'prop-types'

const PageSection = ({children, isInner}) => {
    return <section>
    {
        isInner ? <div className="inner">{children}</div> : {children}
    }
    </section>
}


PageSection.defaultProps = {
    isInner: true
}

PageSection.propTypes = {
    isInner: PropTypes.bool,
    children: PropTypes.node
}

export default PageSection